.stepper-register
  .css-1hv8oq8-MuiStepLabel-label.MuiStepLabel-alternativeLabel {
  background-color: rgba(255, 255, 255, 0.6470588235);
  border-radius: 10px;
  max-width: 200px;
  margin-left: auto;
  margin-right: auto;
}

.menu-icon {
  color: #636e72;
}

.active-list-item {
  background-color: #d63031 !important;
}

.css-je3nfp {
  border-radius: 10px !important;
  overflow: hidden;
}

.MuiDialog-paperWidthSm {
  width: 100% !important;
}

::-webkit-scrollbar {
  width: 10px;
  border-radius: 15px;
  background-color: #d4d4d4;
  cursor: default;
}

::-webkit-scrollbar-thumb {
  background-color: #a62639;
  border-radius: 30px;
  border: 2px solid #ababab;
  cursor: default;
}

::-webkit-scrollbar-button {
  display: none;
}

.css-yzue85 {
  margin: 0 !important;
} /*# sourceMappingURL=style.css.map */
