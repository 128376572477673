

.menu-icon {
  color: #636e72;
}

.active-list-item {
  background-color: #d63031 !important;
}

.css-je3nfp {
  border-radius: 10px !important;
  overflow: hidden;
}
