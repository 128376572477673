// @media (min-width: 600px) {
//   .css-1r9jet7 {
//     min-height: 46px !important;
//   }
// }

// @media (min-width: 600px) {
//   .css-1uksobp {
//     min-height: 40px !important;
//   }
// }

// @media (min-width: 600px) {
//   .css-10gokwy-MuiDrawer-docked {
//     width: 40px !important;
//   }
// }

//scroll
::-webkit-scrollbar {
  width: 10px;
  border-radius: 15px;
  background-color: #d4d4d4;
  cursor: default;
}

::-webkit-scrollbar-thumb {
  background-color: #a62639;
  border-radius: 30px;
  border: 2px solid #ababab;
  cursor: default;
}

::-webkit-scrollbar-button {
  display: none;
}

.css-yzue85 {
  margin: 0 !important;
}
