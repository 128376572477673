body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.color-primary {
  color: #a62639;
}

.card-data-grid .MuiCardContent-root,
.css-sst63a-MuiDialogContent-root {
  padding: 0 !important;
}

.MuiDialog-paper {
  width: 100%;
}

.location-map {
  position: relative;
  border-radius: 5px;
  overflow: hidden;
  min-width: 400px;
  border: 1px solid #5b5b5b !important;
}

.location-map .btn-location-live {
  position: absolute;
  bottom: 10px;
  left: 10px;
  z-index: 9999;
  background-color: rgba(256, 256, 256, 0.75);
  border: 1px solid #a62639;
  border-radius: 5px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s all ease-in-out;
}

.location-map .btn-location-live svg:hover {
  scale: 1.1;
}

.leaflet-control-attribution {
  display: none !important;
}
